<template>
  <div class="row justify-content-start px-8 px-lg-10">
    <div class="col-12">
      <div class="table-responsive" >
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{ $t('commons.date') }}</th>
                    <th scope="col">{{ $t('commons.operation') }}</th>
                    <th scope="col">{{ $t('finance.currency') }}</th>
                    <th scope="col">{{ $t('commons.total') }}</th>
                    <th scope="col">{{ $t('commons.status') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="historyData.length === 0">
                  <td colspan="7" class="text-center">{{ $t('commons.no_data_available') }}</td>
                </tr>
                <tr v-for="(item, index) in historyData" :key="'dep-history-' + index">
                  <th scope="row">{{ index+1 }}</th>
                  <td>{{ item.created_at | dateFormat }}</td>
                  <td>{{ $t('deposit.name') }}</td>
                  <td>{{ item.currency }}</td>
                  <td>{{ item.amount_formatted }}</td>
                  <td>{{ item.status_str }}</td>
                </tr>
            </tbody>
        </table>
        <div class="col-12 row col-sm-12 justify-content-center mt-2" v-if="canLoadMore">
          <form-submit-btn
            :btnClass="'btn btn-primary w-25'"
            @click="loadMore"
            :text="$t('commons.load_more')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dateFormat from "@/config/_date-format";
export default {
  name: "DepositHistory",
  props: {
    selectedCurrency: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  filters: {
    dateFormat
  },
  computed: {
    ...mapState({
      historyLoading: (state) => state.deposit.historyLoading,
      historyData: (state) => state.deposit.historyData,
      historyLinks: (state) => state.deposit.historyLinks,
      historyMeta: (state) => state.deposit.historyMeta,
    }),
    canLoadMore() {
      return (this.historyLinks.next && this.historyLinks.next !== null) ? true : false;
    }
  },
  methods: {
    ...mapActions('deposit', ['GET_TRANSACTIONHISTORY']),
    loadMore() {
      if(this.historyMeta?.current_page && this.canLoadMore) {
        this.GET_TRANSACTIONHISTORY({ currencyCode: this.selectedCurrency.code, page: this.historyMeta.current_page + 1 })
      }
    }
  },
  created() {},
  watch: {
    selectedCurrency: {
      handler(val) {
        if(val?.code) {
          this.GET_TRANSACTIONHISTORY({ currencyCode: val.code })
            /* .then(() => {
              console.log('historyData: ', this.historyData)
              console.log('historyLinks: ', this.historyLinks.next)
              console.log('historyMeta: ', this.historyMeta)
            }) */
        }
      },
      deep: true
    }
  }
};
</script>
