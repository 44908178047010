<template>
<div class="container-fluid">
        <div class="card card-custom">
          <!--begin::Create-->
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">{{ $t('deposit.name') }}</h3>
            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-start px-8 px-lg-10">
              <div class="col-md-7 col-lg-7 col-sm-12 justify-content-start">
                <!--begin::Form Wizard-->
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form class="form">
                    <!--begin::Step 1-->
                    <div data-wizard-type="step-content" data-wizard-state="current">
                      <div class="row">
                        <div class="col-xl-12">
                          <div class="form-group row">
                            <div class="col-lg-6 col-sm-12">
                              <label class="col-form-label">{{ $t('deposit.name') }}</label>
                              <validation-provider
                                rules="required"
                                :name="$t('deposit.name')"
                                v-slot="{ classes, errors }"
                              >
                                <multiselect
                                  v-model="selectedCurrency"
                                  :options="currencies"
                                  :internal-search="true"
                                  :loading="currenciesLoading"
                                  :allow-empty="false"
                                  open-direction="bottom"
                                  deselect-label=""
                                  select-label=""
                                  selected-label=""
                                  @input="onChangeCurrency"
                                  track-by="name"
                                  label="name"
                                  :placeholder="$t('finance.currency')"
                                  name="Currency"
                                  :class="classes"
                                  :disabled="isApproving"
                                >
                                  <template
                                    slot="singleLabel"
                                    slot-scope="{ option }"
                                    >{{ option.name }}</template
                                  >
                                  <template slot="option" slot-scope="{ option }">{{
                                    option.name
                                  }}</template>
                                  <template slot="noOptions">{{
                                    "List is empty"
                                  }}</template>
                                </multiselect>
                                <span class="error__message">{{ errors[0] }}</span>
                              </validation-provider>
                            </div>
                            <div class="col-lg-6 col-sm-12" v-if="canDeposit">
                              <label class="col-form-label">{{ $t('finance.bank') }}</label>
                              <validation-provider
                                rules="required"
                                :name="$t('finance.bank')"
                                v-slot="{ classes, errors }"
                              >
                                <multiselect
                                  v-model="selectedProvider"
                                  :options="providers"
                                  :internal-search="true"
                                  open-direction="bottom"
                                  @input="onChangeProvider"
                                  :loading="providersLoading"
                                  deselect-label=""
                                  select-label=""
                                  selected-label=""
                                  track-by="name"
                                  label="name"
                                  :placeholder="$t('finance.bank')"
                                  name="Bank"
                                  :class="classes"
                                  :disabled="isApproving"
                                >
                                  <template
                                    slot="singleLabel"
                                    slot-scope="{ option }"
                                    >{{ option.name }}</template
                                  >
                                  <template slot="option" slot-scope="{ option }">{{
                                    option.name
                                  }}</template>
                                  <template slot="noOptions">{{
                                    "List is empty"
                                  }}</template>
                                </multiselect>
                                <span class="error__message">{{ errors[0] }}</span>
                              </validation-provider>
                            </div>
                          </div>
                          <div class="form-group row" v-if="canDeposit">
                              <label class="col-12 col-form-label">{{ $t('finance.amount') }}</label>
                              <div class="col-12">
                                <validation-provider
                                  :rules="`required|min_value:${minAmount}`"
                                  :name="$t('finance.amount')"
                                  v-slot="{ classes, errors }"
                                >
                                  <currency-input
                                    class="form-control"
                                    :placeholder="$t('finance.amount')"
                                    :class="classes"
                                    :precision="currencyPrecision"
                                    v-model="formData.amount"
                                    :disabled="isApproving"
                                  />
                                  <span class="error__message">{{ errors[0] }}</span>
                                </validation-provider>
                              </div>
                          </div>
                          <div class="form-group row justify-content-around" v-if="!balanceLoading">
                            <div class="justify-content-start col-md-6 col-sm-12">
                              <span>{{ $t('finance.balance') }}</span>
                            </div>
                            <div class="d-flex flex-row-reverse col-md-6 col-sm-12">
                              <span>{{ balanceByCurrency }} </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--begin::Actions-->
                    <template v-if="canDeposit">
                      <div class="d-flex justify-content-center pt-10" v-if="!transfer">
                        <form-submit-btn
                          :isSubmitting="transferLoading"
                          :btnClass="'btn btn-pill btn-primary w-75'"
                          @click="handleSubmit(createDeposit)"
                          :text="$t('commons.continue')"
                        />
                      </div>
                      <div class="col-12" v-else>
                        <div class="row justify-content-center align-items-center col-12 mb-2">
                          <img :src="selectedProviderLogo" width="95" height="50" alt="bank_image" />
                        </div>
                        <div class="row justify-content-center align-items-center col-12 mb-2">
                          <span class="font-weight-light">{{ transfer.provider.bank }}</span>
                        </div>
                        <div class="row justify-content-center align-items-center col-12 mb-2">
                          <span class="font-weight-light">{{ transfer.provider.address }}</span>
                        </div>
                        <div class="row justify-content-center align-items-center col-12 mb-2">
                          <span class="font-weight-normal"><b>{{ $t('deposit.recipient') }}: </b> {{ transfer.provider.name }}</span>
                        </div>
                        <div class="row justify-content-center align-items-center col-12 mb-2">
                          <span class="font-weight-normal"><b>{{ $t('commons.description') }}: </b> {{ transfer.code }}</span>
                        </div>
                        <div class="col-12 mt-10 mb-2">
                          <div class="col-12 text-center mt-2">
                            <form-submit-btn
                              :btnClass="'btn btn-pill btn-success w-75'"
                              @click="handleApproveDeposit"
                              :isSubmitting="isApprovingDeposit"
                              :text="$t('commons.approve')"
                            />
                          </div>
                          <div class="col-12 text-center mt-2">
                            <form-submit-btn
                              :btnClass="'btn btn-pill btn-secondary px-15 py-3 w-75'"
                              @click="handleCancelDeposit"
                              :isSubmitting="isCancelingDeposit"
                              :text="$t('commons.cancel')"
                            />
                          </div>
                        </div>
                      </div>
                    </template>

                    <template v-if="!canDeposit && !providersLoading && coinAddress">
                        <div class="row justify-content-center align-items-center col-12 mb-2">
                          <span class="font-weight-light">{{ coinLabel }}</span>
                        </div>
                        <div class="row justify-content-center align-items-center col-12 mb-2">
                          <span class="font-weight-light mr-2">{{ $t('finance.wallet_address') }}: {{coinAddress}}</span>
                          <i title="Copy" class="fa fa-copy text-primary hover-opacity-60 cursor-pointer" @click="copyToClipboard(coinAddress)"></i>
                        </div>
                        <div class="row justify-content-center align-items-center col-12 mb-2" v-if="hasWalletTag">
                          <span class="font-weight-light mr-2">{{ $t('finance.wallet_tag') }}: {{walletTag}}</span>
                          <i title="Copy" class="fa fa-copy text-primary hover-opacity-60 cursor-pointer" @click="copyToClipboard(walletTag)"></i>
                        </div>
                    </template>
                    <template v-if="!canDeposit && !providersLoading && !coinAddress">
                      <div class="col-12 row col-sm-12 justify-content-center mt-2">
                        <form-submit-btn
                          :btnClass="'btn btn-primary w-50'"
                          :isSubmitting="isSubmitting"
                          @click="createWalletAddress"
                          :text="$t('finance.create_wallet_address')"
                        />
                      </div>
                    </template>
                    <!--end::Actions-->
                  </form>
                </ValidationObserver>
                <!--end::Form Wizard-->
              </div>
              <div class="col-md-5 col-lg-5 col-sm-12 justify-content-start">
                <div class="pb-3" v-html="$t('deposit.information', { currencyCode: this.currencyCode })"></div>
                <img src="/assets/media/logos/prime-trust-logo.png" />
              </div>
            </div>
          </div>
          <!--end::Create-->
          
          <!--begin::History-->
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">{{ $t('deposit.history') }}</h3>
            </div>
          </div>
          <div class="card-body">
            <History :selectedCurrency="selectedCurrency" />
          </div>
          <!--end::History-->
        </div>
      </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Multiselect from "vue-multiselect";
import History from "./History.vue";
import "vue-multiselect/dist/vue-multiselect.min.css";


export default {
  name: "Deposit",
  data() {
    return {
      formData: {
        currency: null,
        provider_id: null,
        amount: null,
      },
      isSubmitting: false,
      currencyPrecision: 2,
      selectedCurrency: null,
      selectedProvider: null,
      minAmount: 10,
      isCancelingDeposit: false,
      isApprovingDeposit: false,
    };
  },
  components: {
    Multiselect,
    History
  },
  computed: {
    ...mapState({
      currenciesLoading: (state) => state.finance.currenciesLoading,
      currencies: (state) => state.finance.currencies,
      providersLoading: state => state.deposit.providersLoading,
      providers: (state) => state.deposit.providers,
      balanceLoading: (state) => state.finance.balancesLoading,
      balanceByCurrency: (state) => state.finance.balanceByCurrency,
      coinLabel: (state) => state.deposit.coinLabel,
      coinAddress: (state) => state.deposit.coinAddress,
      transferLoading: (state) => state.deposit.transferLoading,
      transfer: (state) => state.deposit.transfer,
      user: (state) => state.customer.user,
      canDeposit() {
        return !this.selectedCurrency?.crypto;
      },
      walletTag() {
        return 1000000000+this.user.id
      },
      hasWalletTag() {
        return ['XRP', 'XLM', 'xlm', 'xrp'].includes(this.selectedCurrency?.code)
      },
      currencyCode() {
        return this.selectedCurrency?.code || 'TRY';
      },
      isApproving() {
        return this.transfer !== null;
      },
      selectedProviderLogo() {
        return this.selectedProvider.logo
      }
    }),
  },
  methods: {
    ...mapActions("finance", ["GET_COMMON_CURRENCIES", "GET_BALANCES_BY_CURRENCY"]),
    ...mapActions("deposit", [
      "GET_PROVIDERS",
      "CREATE_WALLET_ONE",
      "CREATE_DEPOSIT",
      "APPROVE_DEPOSIT",
      "CANCEL_DEPOSIT"
    ]),
    createDeposit() {
      const fData = {
        ...this.formData,
        currency: this.selectedCurrency.code.toLocaleLowerCase(),
        provider_id: this.selectedProvider.id,
      };
      this.CREATE_DEPOSIT(fData)
    },
    onChangeCurrency() {
      this.selectedCurrency && this.setUpForCurrency(this.selectedCurrency);
    },
    onChangeProvider() {
      console.log(this.selectedProvider?.min_pay)
    },
    handleCancelDeposit() {
        if(this.transfer?.code && this.selectedCurrency?.code) {
          this.isCancelingDeposit = true;
          this.CANCEL_DEPOSIT({ transferCode: this.transfer?.code, currencyCode: this.selectedCurrency?.code })
            .then(() => { })
            .catch(() => { })
            .finally(() => {
              this.isCancelingDeposit = false
            })
        }
    },
    handleApproveDeposit() {
        if(this.transfer?.code && this.selectedCurrency?.code) {
          this.isApprovingDeposit = true
          this.APPROVE_DEPOSIT({ transferCode: this.transfer?.code, currencyCode: this.selectedCurrency?.code })
            .then(() => {
              this.selectedProvider = null;
              this.formData.amount = null;
            })
            .catch(() => {})
            .finally(() => {
              this.isApprovingDeposit = false
            })
        }
    },
    setUpForCurrency(currency) {
      const { decimal, crypto, code } = currency;
      this.currencyPrecision = decimal || 2;
      this.GET_PROVIDERS({ isCrypto: crypto, currencyCode: code })
      this.GET_BALANCES_BY_CURRENCY({ currencyCode: code })
    },
    copyToClipboard(copyText) {
      navigator.clipboard.writeText(copyText);
      this.$toast.info(this.$t('commons.copied_msg'))
    },
    createWalletAddress() {
      this.isSubmitting = true;
      this.CREATE_WALLET_ONE({currencyCode: this.selectedCurrency?.code})
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        this.isSubmitting = false;
      })
    }
  },
  created() {
    this.GET_COMMON_CURRENCIES({ kind: "deposit" })
  },
};
</script>
